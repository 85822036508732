.facebook{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-color: transparent;
    padding: 2%;
    background-color: rgb(17, 82, 147);
    color: white;
    margin-bottom: 3%;
}

.iconoF{
     color: white;
     margin-right: 1%;
}

.google{
    width: 100%;
    display: flex !important;
    justify-content: center;
    
}

.iconoUser{
    width: 100%;
    margin-top: 2%;
}
.loginBotom{
    width: 46%;
    border-radius: 0%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: rgb(17, 82, 147);
    border-color: transparent;

}

.fromL{
    
  
}

.registroBotom{
    width: 46%;
    border-radius: 0%;
    margin-left: 2%;
    margin-right: 2%;
    background-color: rgb(209, 60, 60);
    border-color: transparent;
}

.registroBotom:hover{
    background-color: rgba(209, 60, 60, 0.815);
}

.secPrin{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MuiPaper-elevation4{
    box-shadow: none !important;
}

.tabs-box{
    box-shadow: none !important;
    background:white;
    border: none !important;
    color:rgb(25, 118, 210);
}
.tab-box{
    width: 50% ;
    box-shadow: none !important;
}


.a-pdf{
   text-decoration: none;

}

.sec-check{
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-logo_width{
    width: 20%;
    padding: 2%;
}


.btn-atras{
    left: 0%;
    top: 0;
    margin-top: 2.5%;
    margin-left: 2.5%;
}

.div-contraseña{
    background-color: rgb(245, 245, 245);
    height: 100vh;
    padding-top: 10%;
}

.div-contraseña-text{
    background-color: white;
    border-radius: 17px;
    padding: 3%;
}

.h4-contraseña{
    margin-bottom: 3%;
    color: rgb(25, 118, 210);
}

.p-contraseña{
    color:rgb(102, 100, 100) ;
}

@media (max-width:900px){
    .login{
        width: 100% !important;
    }
}