.box-perfil {
    margin-top: var(--var-top);
    margin-bottom: var(--var-top);
    padding-left: 5%;
    padding-right: 5%;
  
}


.box-perfil-multimedia {
    width: 15%;
}

.box-perfil-header {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.box-perfil-header-head {
    height: 15vh;
    border-radius: 10px 10px  0px 0px;
    background-color: rgb(255, 153, 0);
}

.button-update{
    background: var(--color-naranja) !important;
    color: white !important;
    
}

.box-perfil-header-avatar {
    margin-top: -10vh;
   
}

.header-avatar-edit{
    position: absolute;
    top: 0%;
    margin-top: -10vh;
    left: 0%;
    bottom: 0px;
    color: rgb(255, 153, 0);
    background: rgba(0, 0, 0, 0.349);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    opacity: 0;
}

.header-avatar-edit:hover{
    opacity: 1;
    cursor: pointer;
}


.box-perfil-aditional{
        position:absolute;
        top: 40%;
        left: 9%;
        width: 81%;
}

.box-perfil-aditional>p{

}


@media (max-width:900px){
    .text-start{
        padding-top: 20% !important;
        
    }

    .margen-text{
        margin-left: 0% !important;
        width: 100%;
        margin-top: 10% !important;
    }
    .margen-text2{
        width: 100%;
        margin-top: 10% !important;

    }

    .borrar-categoria{
        display: none !important;
    }
}