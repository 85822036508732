.section-box{
    margin-top: 9%;
    padding-left: 7%;
    padding-right: 5%;
    
}

.box-img{
    width: 12%;
    height: 20vh;
}

.box-subimg{
    width: 9%;
    height: 12.5vh;
    margin-right: 2%;
}

.section-box-info{
    width: 88%;
}

.section-box-caracteristics{

}

.section-box-vendor{
    padding-left:13% ;
   
}

.btn-edit{
    z-index: 2 !important;
}

.sec-pub{
    display: flex;
}



@media (max-width:900px){
    
.box-subimg{
    width: auto !important;
    height: 8vh !important;
}

}