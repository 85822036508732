.box-categoria{
    display: flex;
    padding-top: 9%;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 3%;
}

.box-categoria-productos{
    display: flex;
    width: 70%;
    flex-wrap: wrap;
}

.box-categoria-productos > .box-card-item{
        width: 32% !important;
}

.box-categoria-info{
    width: 30%;
    display: flex;
    justify-content: center;
}

.box-categoria-tipo{
    width:40vh;
}

li{
    list-style: none;
}

.chip-cat-uno{
    display: table !important;
    margin-top: 2% !important;
    background-color: rgb(25, 118, 210) !important;
    color: white !important;
    
}


/******** filtro y modal */



.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.link-modal{
    text-decoration: none;
    color: gray;

}


/*----------------------------------------------*/

@media (max-width:900px){
    .box-categoria{
        display: block;
        margin-top: 10%;
        
    }

    .box-categoria-info,.box-categoria-productos{
        width: 100%;
    }
    

    
}