.sec-div{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 5%;
}

.textF-step{
    margin-left: 1%;
    margin-right: 1%;
    width: 48%;
}

.textF-step-dos{
    margin-left: 1%;
    margin-right: 1%;
    width: 98%;
}

.text-h6{
    width: 25%;
    text-align: left;
}

.titulo-step{
    text-align: left;
    font-weight: bold;
    
}