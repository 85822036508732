.footer{
    background-color: rgb(25, 118, 210) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    bottom: 0;
    position: relative;
    height: 5vh;
}

.pFooter{
    color: white;
}

@media (max-width:900px){
    .pFooter{
        font-size: 12px;
    }
}