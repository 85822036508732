.box-soporte{
    margin-top: 10%;
    padding-left: 5%;
    padding-right: 5%;
}

.form-contacto{
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  
  }
  
  .tituloCon{
      text-align: left;
      color: rgb(146, 145, 145);
      font-family: Georgia, 'Times New Roman', Times, serif;
      font-size: 30px;
      padding-bottom: 4%;
  }
  
  .formularioCon{
      background-color: white;
      border-radius: 0%;
      font-family: Georgia, 'Times New Roman', Times, serif;
      margin-top: 3%;
      
  }
  
  .formularioCon :focus{
      outline-style: none !important;
  }
  
  
  .formularioCon:hover{
      background-color: rgb(202, 202, 202);
      
  }
  
  .formularioCon:focus{
     outline: 0 !important;
      
  }
  
  .botonCon{
      background-color: rgb(146, 145, 145) ;
      border-color: transparent;
      border-radius: 0%;
      padding-left: 5%;
      padding-right: 5%;
      font-family: Georgia, 'Times New Roman', Times, serif;
      display: block;
      margin-top: 3%;
      margin-left: auto;
      margin-right: auto;
  }
  
  .botonCon:hover{
      background-color: rgb(202, 202, 202) ;
      border-color: transparent;
  }
  

  .p-contacto{
      font-size: 40px;
      margin-bottom: 2%;
  }
  
 
  @media (max-width: 750px){
      .form-contacto{
          width: 90%;
      }
  
      .hr-Contacto{
          width: 70%;
      }
  
      .botonCon{
          margin-left: auto;
          margin-right: auto;
      }
  
      .p-contacto{
          width: 90%;
          margin-left: auto;
          margin-right: auto;
          font-size: 15px;
      }

      .box-soporte{
        margin-top: 20%;
    }
  }