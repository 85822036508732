.sectionInicio{
    background-color: rgb(17, 82, 147);
}

.divInicio{
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 4%;
    text-align: left;
    color: white;
}

.botonAviso{
    padding-left: 3% !important;
    padding-right: 3% !important;
}

.hUnoInicio{
    font-size: 60px;
    padding-top: 10% !important;

}

.peInicio{
    font-size: 20px;
}

@font-face {
    font-family: fuenteI;
    src: url(../Fuentes/NORMT___.TTF);
}

.sectionInicioDos{
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 100%;
}


.icono{
    font-size: 60px !important;
    color: rgb(17, 82, 147);
    margin-bottom: 2%;
}

.ColInicio{
    padding: 3% !important;
}

.icono:hover{
    color: tomato;
    cursor: pointer;
}

.hTresInicio{
    text-align: left;
    color: silver;
}


.box-card{
        flex-wrap: wrap;
}

.box-card-item{
    margin-top: 1%;
    margin-right: 1%;
    width: 24% !important;
    height: 50vh;
}

.box-spinner{
    height: 70vh;
}

@media (max-width:900px){

    .hUnoInicio{
        font-size: 40px;
    }

    .peInicio{
        font-size: 10px;
    }
    
    .ColInicio{
        width: 50% !important;
        font-size: 10px;
    }

    .botonAviso{
        font-size: 10px !important;
        
    }

    .hTresInicio{
        font-size: 15px ;
        
    }

    .icono{
        font-size: 40px !important;
    }

    .MuiPagination-ul{
        font-size: 20px;
    }

    .box-card-item{
        width: 100% !important;
        height: auto !important;
    
    }

    .MuiTypography-body1{
        font-size: 12px !important;
    }
    .MuiTypography-h5{
        font-size: 18px !important;

    }

    .MuiTypography-body2{
        font-size: 10px !important;
    }

    .makeStyles-botonI-10{
        font-size: 10px !important;

    }

    .MuiCardContent-root{
        padding-bottom: 0% !important;
    }
    
}