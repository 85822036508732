.cardA{
    border-color: transparent;
    text-align: left;
    
}

.imgA{
    width: 100%;
    height: auto;
    display: block;
    margin-right: auto;
}

.cardAdos{
    font-family: sans-serif;
}



.colA{
    border-left: 1px solid rgb(175, 173, 173);
    
}

.divAA{
    display: grid;
    justify-content: center;
    align-items: center;
    height: 30vh;
}

.botonA{
    border-radius: 0px;
}

.saecMis{
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: var(--var-top);
}

.back-icon{
   
    margin-left: 7% !important;

}