.box-politicas{
    margin-top: 7% !important;
    padding-left: 5%;
    padding-right: 5%;
    text-align: justify;
}

@media (max-width:900px) {
    .tit-pol{
        font-size: 20px;
    }
}