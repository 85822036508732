
.MuiAppBar-colorPrimary{
    background-color: rgb(25, 118, 210) !important;
}



.logo{
    width: 15%;
    padding: 2%;
}


.link-carrito{
    color: white;
}

.linkM{
    color: white;
    text-decoration: none;

}


.list-menu{
    text-decoration: none;
}

.linksubmenu{
    text-decoration: none !important;
}

@media (max-width:900px){
    

.logo{
    width: 40%;
}

.linkpub{
    display: none;
}

.MuiIconButton-edgeEnd {
    margin-right: 50px !important;
}
}


