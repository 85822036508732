.section-box{
    margin-top: 9%;
    padding-left: 7%;
    padding-right: 5%;
    
}

.box-img{
    width: 12%;
    height: 20vh;
}

.box-subimg{
    width: 9%;
    height: 12.5vh;
    margin-right: 2%;
}

.section-box-info{
    width: 88%;
}

.section-box-caracteristics{
    padding-left:13% ;
}

.section-box-vendor{
    padding-left:13% ;
   
}

.text-caracteristica{
    width: 50%;
}

.form-producto{
    width: 40%;
    font-size: 12px;
}
@media (max-width:900px){
    .icono-photo{
        font-size: 15px !important;
    }
    .section-box{
        margin-top: 25%;
        font-size: 12px !important;
    }
    .section-box-caracteristics{
        padding-left:0% ;
        font-size: 12px !important;

    }

    .caracteristicas{
        display: none !important;
    }
    .text-caracteristica{
        font-size: 10px !important;

    }
    .section-box-vendor{
        padding-left:0% ;
       
    }

    .MuiOutlinedInput-input {
        padding: 2px !important; 
    }

    .MuiAvatar-root{
        margin-left: 250% !important;
    }
    .text-caracteristica{
        width: 100%;
    }
    .form-producto{
        width: 100%;
        margin-left: 0% !important;
    }

}
