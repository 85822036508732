.secP{
    min-height: 95vh;
    padding-bottom: var(--var-top);
}

.rowPrincipal{
    text-align: left;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: var(--var-top)!important;
}

.colDos{
    background-color: rgb(245, 245, 245);
}

.colUno{
    background-color: rgb(235, 235, 235);
}

.carrusel{
    height: 70vh !important;
}

._3q7r8 {
    background-color: white !important;
    
}

._dZ8C- {
    display: none !important;
}

.divDes{
    text-align: left;
    padding-left: 5% !important;
    padding-right: 5% !important;
    padding-top: 1%;
}